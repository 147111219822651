.footer {
    background-color: #f4f7fa;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
    ul {
        padding: 0px 0px;
        margin: 0px 0px;
    }
}

.footer img {
    margin-bottom: 20px;
}

.footer-menu ul {
    list-style-type: none;
}

.footer-menu li {
    display: inline;
    line-height: 2;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: uppercase;
}

.footer-menu li a {
    color: #707570;
    text-decoration: none;
}

.footer-text p {
    font-size: 14px;
    color: #707570;
    line-height: 2;
    letter-spacing: 1px;
}