p, div {
    font-size: 1.05rem;
}

.m-t-60 {
    margin-top: 60px;
}

.form-check {
    margin-top: 5px;
}

.form-check-input {
    margin-top: 0px;
}

.btn-success {
    background-color: #28a745;
    border-color: #28a745;
}

.task-right > .task-right-header-status {
    cursor: default;
}

.label {
    &.label-brown{
        background: rgb(100, 62, 26);
        color: #ffffff;
    }
    &.label-orange{
        background: rgb(231, 123, 21);
        color: #ffffff;
    }
    &.label-success{
        background: #5cb85c;
        color: #ffffff;
    }
    &.label-default{
        background: #777;
        color: #ffffff;
    }
    &.label-purple{
        background: purple;
        color: #ffffff;
    }
    &.label-yellow{
        background: rgb(168, 180, 0);
        color: #ffffff;
    }
}

p {
    line-height: 1.45;
}

nav {
    .nav-item {
        font-size: 14px;
        button {
            margin: auto;
        }
    }
    p {
        margin-bottom: 0px;
    }
}

.page-link {
    padding: 0.75rem 1.1rem;
}

li {
    line-height: 1.5em;
}

.word-wrap {
    word-wrap: break-word;
}

.pricing-list {
    list-style: none;
    padding-inline-start: 0px;
}

.footer-logo {
    max-height: 100px;
    max-width: 80%;
}

.btn-action-inactive {
    background-color: #748892;
    border: 2px solid #748892;
    border-radius: 0;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 3;
    padding: 0 21px;
    text-transform: uppercase;
    outline: none;
    transition: 200ms;
    &:hover{
        background: transparent;
        outline: none;
        color: #748892;
        border-color: #748892;
    }
}

.filter-bar .navbar {
    z-index: 1;
    height: auto;
}

h6 {
    line-height: 1.4em;
}

.invalid-feedback {
    margin-top: -30px;
}

.signup-btn {
    box-shadow: 0 10px 18px 0 rgba(62,57,107,.2);
}

.image-credits {
    color: #707570;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
}

.trusted-by-section, .lead-innovator-section {
    h2 {
        font-size: 28px;
        color: #111111;
        font-weight: 600;
        line-height: 1.4;
    }

    img {
        height: 120px;
        width: auto;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .owl-carousel .owl-item {
        text-align: center;
        img {
            height: 120px; 
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.lead-innovator-section {
    margin-bottom: 80px;
}

.preview-card {
    .business-name {
        font-size: 18px;
        color: #111;
        font-weight: 400;
        margin-bottom: 0.5em;
        line-height: 1.2;
    }
    .address, .location-code {
        font-size: 14px;
        color: #888;
        font-weight: 400;
        line-height: 1.5;
    }
}

.privacy, .terms, .support-page, .posting {
    margin-top: 120px;
    letter-spacing: 0.4px;
    line-height: 1.7;

    h1 {
        font-size: 40px;
    }

    h2 {
        font-weight: 300;
        font-size: 35px;
        margin-top: 1em;
    }

    p {
        margin-top: 1em;
    }
    
    ul {
        margin-top: 0.5em;
        list-style: disc;
        padding: 0 0 1.313em 1.655em;
        ul {
            list-style: circle;
        }
    }

    strong {
        font-weight: bold;
    }

    .tailspace {
        height: 40px;
    }
}